
/* Fix the flickering in google charts */
svg > g > g.google-visualization-tooltip { pointer-events: none } 

.App {
  text-align: center;
}

.arrow {
  width: 10px;
  height: 10px;
  margin: 20px 20px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  position: relative;
}

.line {
  width: 20px;
  height: 2px;
  background: #000;
  position: absolute;
  bottom: -1px;
  transform: rotate(-45deg);
  transform-origin: left;
}

.canvas-container {
  overflow: auto;
}

.canvas {
  width: 1280px;
  height: 720px;
}

.bg {
  background-image: url("https://uploads-ssl.webflow.com/5c4630067e2ad67b8de362a5/5c5353ed999f2ea6f6984701_logo_sensei_green.png");
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #596d67;
}

.App-modal{
  word-break: break-all;
}

.App-filter-options {
  background-color: #282c34;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  font-size: calc(5px + 1.3vmin);
  color: rgb(255, 255, 255);
}

.App-header {
  background-color: #282c34;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(4px + 2vmin);
  color: rgb(255, 255, 255);
}

.col {
  height: 5vh;
  min-height: 5vh;
}
.react-bootstrap-table-pagination-total{
  color: rgb(0, 0, 0);
  float: left;
}

.react-bootstrap-table-pagination-total{
  color: rgb(0, 0, 0);
  margin-top: 6px;
}

.dropdown{
  float: left;
}


.react-bootstrap-table{
  margin-top: 2vh;
  margin-bottom: 2vh;
}


.App-table {
  background-color: #ffffff;
  max-width: 1400px;
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  flex-direction: column;
  word-break: break-all;
  font-size: calc(5px + 1vmin);
}


.App-datepicker {
  font-size: calc(4px + 1.2vmin);
}

.Nav-bar {
  background-color: #282c34;
}

.App-body {
  background-color: #282c34;
  gap: 50px;
  min-height: 90vh;
  display: flex;
  flex-direction:  row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: calc(4px + 2vmin);
  color: rgb(0, 0, 0);
}


.App-login-body {
  vertical-align: middle;
  background-color: #e6e8ec;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 100px;
  font-size: calc(4px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-body-text {
  color: rgb(255, 255, 255);
}

.Completed-status{
  color: rgb(12, 136, 19);
}

.Waiting-status{
  color: rgb(240, 187, 11);
}

.Generating-status{
  color: rgb(0, 76, 255);
}

.Error-status{
  color: rgb(218, 7, 7);
}


.Status {
  text-align: center;
  align-items: center;
  font-size: calc(4px + 1vmin);
  color: rgb(0, 0, 0);
}

.Progress-stack {
  justify-content: center;
}

.App-labels {
  text-align: left;
  font-size: calc(4px + 1vmin);
  color: rgb(0, 0, 0);
}

.App-code{
  color: rgb(0, 0, 0);
}

.CardList{
  width: 15%;
  min-width: 300px;
  overflow: auto;
  padding-right: 2rem;
  height: 1080px;
}

.Card-body{
    min-width: 230px;
}

.Login-card-body{
  min-width: 300px;
}

.Changes-Table{
  margin-left: 10%;
  margin-right: 10%;
  font-size: calc(4px + 1.4vmin);
}
